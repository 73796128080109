
/*=============================================
=            contact            =
=============================================*/


/*-- Map --*/
.contact-map {
    
    iframe{
        height: 400px;
        width: 100%;
        border: none;
    }
}

/*-- Contact Information --*/
.contact-information {

    @media #{$tablet-device}{
        margin-bottom: 50px;
    }

    @media #{$large-mobile}{
        margin-bottom: 30px;
    }

    & h3 {
        margin-bottom: 30px;
    }
    & ul {
        & li {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            & .icon {
                width: 50px;
                & i {
                    font-size: 30px;
                }
            }
            & .text {
                max-width: calc(100% - 50px);
                & span, & a {
                    display: block;
                    line-height: 24px;
                    max-width: 230px;

                    &:hover{
                        color: $theme-color--default;
                    }
                }
            }
        }
    }
}

/*-- Contact Form --*/

.contact-form input::placeholder {
    color:#697E8B;
}
.contact-form select::placeholder {
  // desde imdex
}
.contact-form textarea::placeholder {
    color:#697E8B;
}

.contact-form {
    & h3 {
        margin-bottom: 30px;
    }
    & input {
        width: 100%;
        height: 60px;
        border: 1px solid #eeeeee17;
        padding: 16px 20px;
        color:#697E8B;
        background-color: #ffffff00;
    }
    & textarea {
        height: 207px;
        width: 100%;
        color:#697E8B;
        height: 240px;
        border: 1px solid #eeeeee17;
        background-color: #ffffff00;
        padding: 10px 20px;
        resize: none;
    }
    & input[type="submit"], & button, & .submit {
        width: 100%;
        height: 60px;
        border: none;
        padding: 5px 30px;
        background-color: #ff5730;
        color: $white;
        text-transform: capitalize;
        font-weight: 300;
        margin-top: 15px;
        &:hover {
            background-color: #fff!important;;
            color: #ff5730;
        }
    }


    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
      }

}

.form-message{
    line-height: 2;
}

/*=====  End of contact  ======*/

